import {
  CaseProps,
  ConditionProps,
  DefaultProps,
  ElseIfProps,
  ElseProps,
  IfProps,
  SwitchProps
} from './types';

function Condition({ children }: ConditionProps) {
  if (Array.isArray(children))
    return (
      children.find((child, index, array) => {
        return (
          child.props.condition ||
          (index === array.length - 1 && child.props.condition === undefined)
        );
      }) || null
    );
  return children.props?.condition ? children : null;
}

function If({ children }: IfProps) {
  return children;
}

function Else({ children }: ElseProps) {
  return children;
}

function ElseIf({ children }: ElseIfProps) {
  return children;
}

function Switch({ children, expression }: SwitchProps) {
  if (Array.isArray(children))
    return (
      children.find(
        (child, index, array) =>
          child.props.expression === expression ||
          (index === array.length - 1 && child.props.expression === undefined)
      ) || null
    );
  return children.props.expression === expression ? children : null;
}

function Case({ children }: CaseProps) {
  return children;
}

function Default({ children }: DefaultProps) {
  return children;
}

Condition.If = If;
Condition.Else = Else;
Condition.ElseIf = ElseIf;
Condition.Switch = Switch;
Condition.Case = Case;
Condition.Default = Default;

export default Condition;
