/* eslint-disable import/no-absolute-path */
import { InfoDuotoneIcon } from '@bws-bitfy/icons-react';
import { Alert, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { warningColor } from 'themes/dark-theme';
import Text from '../Text';

const EnvironmentAlert: React.FC = () => {
  const theme = useTheme();
  return (
    <Alert
      icon={false}
      sx={{
        borderRadius: 0,
        bgcolor: `${warningColor.overlay} !important`,
        display: 'flex',
        justifyContent: 'center'
      }}
      severity="warning"
    >
      <Box display="flex" alignItems="center">
        <Box flex={1} display="flex" alignItems="center">
          <InfoDuotoneIcon fill={theme.palette.warning.light} />
          <Text
            fontWeight={500}
            sx={{ color: warningColor.light }}
            marginLeft={2}
            noWrap
            intlPath="common_translations.sandbox_env_alert_title"
          />

          <Text
            fontWeight={500}
            display={{ xs: 'none', lg: 'inherit' }}
            sx={{
              color: warningColor.lighter,
              fontSize: '1.5rem',
              marginBottom: 0.5
            }}
            marginX={2}
            variant="h6"
            lineHeight={1.167}
          >
            •
          </Text>
        </Box>
        <Box display={{ xs: 'none', lg: 'inherit' }}>
          <Text
            fontWeight={500}
            sx={{ color: warningColor.lighter }}
            tagProps={{
              emphasis: {
                fontWeight: 500,
                color: warningColor.light
              }
            }}
            intlPath="common_translations.sandbox_env_alert_description"
          />
        </Box>
      </Box>
    </Alert>
  );
};

export default EnvironmentAlert;
