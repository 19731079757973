import {
  CopyIcon,
  EmailDuotoneIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  SendIcon,
  TwitterIcon,
  YoutubeIcon
} from '@bws-bitfy/icons-react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  useTheme
} from '@mui/material';
import Button from 'components/atoms/Button';
import ReCaptcha from 'components/atoms/ReCaptcha/ReCaptcha';
import Text from 'components/atoms/Text';
import ContactMessageForm from 'components/molecules/ContactMessageForm';
import SectionBackground from 'components/website/molecules/sectionBackground';
import { IS_DEV_ENV } from 'constants/environments';
import { socialMediaURL } from 'constants/socialMediaURL';
import { HookCallbackFunctions } from 'hooks/types/index';
import useAuth from 'hooks/useAuth';
import { SnackbarMessage, useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { IContactMessage, sendContactMessage } from 'services/contactUs';

interface ContactSectionProps {
  trackingTitle?: string;
}

const ContactSection: React.FC<ContactSectionProps> = ({ trackingTitle }) => {
  const theme = useTheme();
  const { t } = useTranslation('webSiteEn');
  const useAuthProperties = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(
    async (isRefresh?: boolean) => {
      if (!executeRecaptcha) {
        return;
      }

      const _token = await executeRecaptcha();
      if (IS_DEV_ENV) {
        console.log(`${isRefresh ? 'REFRESH_' : ''}RECAPTCHA`, _token);
      }
      useAuthProperties.handleReCaptchaToken(_token);
    },
    [executeRecaptcha]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      handleReCaptchaVerify(true);
    }, 110000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleContactMessage = async (
    contactMessage: IContactMessage,
    callbacks?: HookCallbackFunctions
  ) => {
    const { onError, onSuccess } = callbacks ?? {};
    setLoading(true);

    await sendContactMessage(contactMessage, useAuthProperties.reCaptchaToken)
      .then((response) => {
        if (response.success) {
          enqueueSnackbar({
            type: 'success',
            title: response?.message
          } as unknown as SnackbarMessage);
          onSuccess?.(response);
        } else {
          enqueueSnackbar({
            type: 'error',
            title: response?.message || 'Error on send data'
          } as unknown as SnackbarMessage);
          onError?.(response);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <section id="contactSection" style={{ width: '100%' }}>
      <SectionBackground>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
          paddingBottom={{ xs: '64px', md: '0px' }}
        >
          <Grid container>
            <Grid
              item
              pb="32px"
              xs={12}
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              <Text
                pt={{ xs: 0, md: 3 }}
                variant="h2"
                color="text.primary"
                fontSize={{ xs: '1.25rem', md: '2.125rem', lg: '2.5rem' }}
                sx={{ fontWeight: 500 }}
              >
                {t('webSiteEn.StartNowSection.title')}
              </Text>
              <Text
                pt={2}
                variant="h6"
                color="text.secondary"
                fontSize={{ xs: '1rem', md: '1.25rem', lg: '1.5rem' }}
                sx={{ fontWeight: 500 }}
                textAlign="center"
              >
                {t('webSiteEn.StartNowSection.description')}.
              </Text>
            </Grid>

            <Grid item xs={12} container wrap="wrap-reverse" spacing={4}>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                lg={6}
                display="flex"
                flexDirection="column"
              >
                <Card
                  elevation={0}
                  sx={{
                    marginBottom: 4
                  }}
                >
                  <CardContent
                    sx={{
                      '&:last-child': {
                        paddingBottom: theme.spacing(4)
                      }
                    }}
                  >
                    <ContactMessageForm
                      trackingTitle={trackingTitle}
                      loading={loading}
                      initialValues={{
                        name: '',
                        email: '',
                        phone: '',
                        message: ''
                      }}
                      onSubmit={handleContactMessage}
                    />
                  </CardContent>
                </Card>
                <Box>
                  <ReCaptcha position="inline" />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} md={5} lg="auto" pb={2}>
                <Box display="flex" alignItems="center">
                  <EmailDuotoneIcon fill={theme.palette.primaryOverlays[200]} />
                  <Text
                    pl={1}
                    variant="h6"
                    fontWeight={500}
                    color={theme.palette.primaryOverlays[200]}
                    intlPath="webSiteEn.Contact.title"
                  />
                </Box>
                <Text
                  pt={4}
                  variant="h6"
                  fontWeight={500}
                  color={theme.palette.text.primary}
                  intlPath="webSiteEn.Contact.contact_by_email"
                />
                <Box display="flex" alignItems="center">
                  <Text
                    variant="body1"
                    fontWeight={400}
                    color={theme.palette.text.secondary}
                  >
                    contact@bitfy.app
                  </Text>
                  <IconButton>
                    <CopyIcon fill={theme.palette.iconSecondary} />
                  </IconButton>
                </Box>
                <Button
                  sx={{
                    backgroundColor: theme.palette.divider,
                    mt: 2,
                    mb: 4
                  }}
                  startIcon={<SendIcon />}
                  intlpath="webSiteEn.Contact.send_email"
                />
                <Text
                  variant="h6"
                  fontWeight={500}
                  color={theme.palette.text.primary}
                  pb="10px"
                  intlPath="webSiteEn.Footer.social_media_text"
                />
                <Box display="flex" columnGap="16px">
                  <IconButton
                    onClick={() => {
                      window.open(socialMediaURL.INSTAGRAM);
                    }}
                    sx={{
                      backgroundColor: theme.palette.divider,
                      borderRadius: '12px',
                      ':hover': {
                        backgroundColor: theme.palette.divider
                      }
                    }}
                  >
                    <InstagramIcon fill={theme.palette.iconSecondary} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      window.open(socialMediaURL.FACEBOOK);
                    }}
                    sx={{
                      backgroundColor: theme.palette.divider,
                      borderRadius: '12px',
                      ':hover': {
                        backgroundColor: theme.palette.divider
                      }
                    }}
                  >
                    <FacebookIcon fill={theme.palette.iconSecondary} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      window.open(socialMediaURL.YOUTUBE);
                    }}
                    sx={{
                      backgroundColor: theme.palette.divider,
                      borderRadius: '12px',
                      ':hover': {
                        backgroundColor: theme.palette.divider
                      }
                    }}
                  >
                    <YoutubeIcon fill={theme.palette.iconSecondary} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      window.open(socialMediaURL.LINKEDIN);
                    }}
                    sx={{
                      backgroundColor: theme.palette.divider,
                      borderRadius: '12px',
                      ':hover': {
                        backgroundColor: theme.palette.divider
                      }
                    }}
                  >
                    <LinkedinIcon fill={theme.palette.iconSecondary} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      window.open(socialMediaURL.TWITTER);
                    }}
                    sx={{
                      backgroundColor: theme.palette.divider,
                      borderRadius: '12px',
                      ':hover': {
                        backgroundColor: theme.palette.divider
                      }
                    }}
                  >
                    <TwitterIcon fill={theme.palette.iconSecondary} />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </SectionBackground>
    </section>
  );
};

export default ContactSection;
