export const Routes = {
  // ==== PUBLIC ROUTES ====
  LANDING_PAGE: '/',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  WS_SERVICES: '/services',
  PRICING: '/pricing',
  GET_STARTED: '/get-started',
  DOCUMENTATION: 'https://docs.bitfybaas.com/',
  FORGOT_PASSWORD: '/forgot-password',
  CONFIRM_REGISTER: '/confirm-register',

  // ==== END OF PUBLIC ROUTES ====

  // ==== PROTECTED ROUTES ====
  DASHBOARD: '/app/dashboard',
  PLANS_AND_PAYMENTS: '/app/plans-and-payments',
  MY_ACCOUNT: '/app/my-account',
  MY_ACCOUNT_VERIFY_IDENTITY: '/app/my-account/verify-identity',
  SETTINGS: '/app/settings',
  SUPPORT: '/app/support',
  LOGS: '/app/logs',

  INTEGRATIONS: '/app/integrations',
  INTEGRATION_DETAILS: '/app/integrations/:integration_name',

  SERVICES: '/app/services',
  SERVICES_WALLETS: '/app/services/wallets',
  SERVICES_NETWORK_WALLETS: '/app/services/wallets/:network_id',
  SERVICES_NETWORK_WALLET_DETAILS:
    '/app/services/wallets/:network_id/:wallet_id',

  SERVICES_VAULTS: '/app/services/vaults',
  SERVICES_TRANSACTION_POLICY: '/app/services/vaults/transaction-policy',
  SERVICES_TRANSACTION_POLICY_DETAILS:
    '/app/services/vaults/transaction-policy/:id',
  SERVICES_TRANSACTION_POLICY_CREATION:
    '/app/services/vaults/transaction-policy/create',

  SERVICES_BUY_AND_SELL: '/app/services/buy-and-sell',
  SERVICES_NETWORK_BUY_AND_SELL: '/app/services/buy-and-sell/:symbol',

  SERVICES_WEBHOOKS: '/app/services/webhooks',
  SERVICES_NETWORK_WEBHOOKS: '/app/services/webhooks/:symbol',
  SERVICES_NETWORK_ADDRESS_WEBHOOKS: '/app/services/webhooks/:symbol/:address',

  SERVICES_SWAP: '/app/services/swap',
  SERVICES_SWAP_NEW: '/app/services/swap/new-swap',

  SERVICES_ASSET_TOKENIZATION: '/app/services/asset-tokenization',
  SERVICES_CREATE_ASSET_TOKENIZATION: '/app/services/asset-tokenization/create',
  SERVICES_EDIT_ASSET_TOKENIZATION:
    '/app/services/asset-tokenization/edit/:smart_contract_id',
  SERVICES_DETAILS_ASSET_TOKENIZATION:
    '/app/services/asset-tokenization/details/:smart_contract_id',

  SERVICES_NFT: '/app/services/nft',
  SERVICES_NFT_CREATE: '/app/services/nft/:id/create',
  SERVICES_NFT_COLLECTION_DETAILS: '/app/services/nft/:id',
  SERVICES_NFT_DETAILS: '/app/services/nft/details/:id',

  VAULTS: '/app/services/vaults',
  VAULT_DETAILS: '/app/services/vaults/:id',
  VAULT_NETWORK_WALLETS: '/app/services/vaults/:id/:networkId',
  VAULT_WALLET_DETAILS: '/app/services/vaults/:id/:networkId/:walletId',

  SERVICES_TAXES_PAYMENT: '/app/services/taxes-payment'
};
