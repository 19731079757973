import { Box, Link, Typography } from '@mui/material';
import Image from 'next/image';
import If from '../If';

interface ReCaptchaProps {
  position?: 'bottomleft' | 'inline';
}

const ReCaptcha: React.FC<ReCaptchaProps> = ({ position }) => {
  return (
    <Box
      sx={{
        zIndex: 90000000000000000,
        height: '60px',
        display: 'flex',
        position: position === 'bottomleft' ? 'fixed' : 'inherit',
        left: 0,
        top: '85%'
      }}
    >
      <If condition={position === 'inline'}>
        <Box
          sx={{
            backgroundColor: '#4A90E2',
            width: '186px',
            height: '60px',
            color: 'text.primary',
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: '10px',
            borderTopLeftRadius: '3px',
            borderBottomLeftRadius: '3px'
          }}
        >
          <Box>
            <Typography
              component="span"
              sx={{
                fontSize: '0.75rem',
                whiteSpace: 'nowrap',
                cursor: 'default'
              }}
            >
              protected by{' '}
              <Typography component="span" fontWeight={500}>
                reCAPTCHA
              </Typography>
            </Typography>
          </Box>
          <Box display="flex" sx={{ fontSize: '0.625rem' }}>
            <Link
              underline="hover"
              href="https://www.google.com/intl/pt-BR/policies/privacy/"
              color="text.primary"
            >
              Privacy
            </Link>
            <Typography component="span" fontSize="inherit" marginX={0.5}>
              -
            </Typography>
            <Link
              underline="hover"
              href="https://policies.google.com/terms"
              color="text.primary"
            >
              Terms
            </Link>
          </Box>
        </Box>
      </If>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#101935',
          width: position === 'bottomleft' ? '75px' : '70px',
          height: position === 'bottomleft' ? '65px' : '60px',
          borderTopRightRadius: '3px',
          borderBottomRightRadius: '3px',
          flexDirection: 'column'
        }}
      >
        <If condition={position === 'bottomleft'}>
          <Image src="/images/recaptcha.svg" width={36} height={36} />
        </If>
        <If condition={position === 'inline'}>
          <Image src="/images/recaptcha.svg" width={44} height={44} />
        </If>
        <If condition={position === 'bottomleft'}>
          <Box display="flex" sx={{ fontSize: '0.625rem' }}>
            <Link
              underline="hover"
              href="https://www.google.com/intl/pt-BR/policies/privacy/"
              color="helperText"
            >
              Privacy
            </Link>
            <Typography
              component="span"
              color="helperText"
              fontSize="inherit"
              marginX={0.5}
            >
              -
            </Typography>
            <Link
              underline="hover"
              href="https://policies.google.com/terms"
              color="helperText"
            >
              Terms
            </Link>
          </Box>
        </If>
      </Box>
    </Box>
  );
};

export default ReCaptcha;
