interface IFProps {
  condition: boolean;
  children: React.ReactElement;
}

function If({ condition, children }: IFProps) {
  if (condition) {
    return children;
  }

  return null;
}

export default If;
